import { withAITracking } from '@microsoft/applicationinsights-react-js'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ai } from '../services/AITelemetryService'
import { ITelemtryService } from '../services/ITelemtryService'

export interface TelemetryProps {
    instrumentationKey: string
}

export interface TelemetryState {
    initialized: boolean
}

interface TelemetryContext {
    telemetryService?: ITelemtryService
}

export const TelemetryContext = React.createContext<TelemetryContext>({})

export const TelemetryProvider = withAITracking(
    ai.reactPlugin,
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    class TelemetryTelemetryProvider extends Component<RouteComponentProps & TelemetryProps, TelemetryState> {
        constructor(props: RouteComponentProps & TelemetryProps) {
            super(props)

            this.state = {
                initialized: false,
            }

            const { history, instrumentationKey } = this.props,
                AppInsightsInstrumentationKey = instrumentationKey

            if (!this.state.initialized && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
                ai.initialize(AppInsightsInstrumentationKey, history)
                this.state = { ...this.state, initialized: true }
            }
        }

        render() {
            const { children } = this.props
            return <TelemetryContext.Provider value={{ telemetryService: ai }}>{children}</TelemetryContext.Provider>
        }
    }
)
