import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import * as H from 'history'
import { ICustomProperties, ITelemtryService } from './ITelemtryService'

class AITelemetryService implements ITelemtryService {
    reactPlugin: ReactPlugin

    appInsights?: ApplicationInsights

    constructor() {
        this.reactPlugin = new ReactPlugin()
    }

    initialize(instrumentationKey: string, browserHistory: H.History) {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service')
        }
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx')
        }

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: {
                        history: browserHistory,
                    },
                },
            },
        })

        this.appInsights.loadAppInsights()
    }

    trackEvent(name: string, customProperties?: ICustomProperties) {
        this.appInsights?.trackEvent({ name }, customProperties)
    }
}

const createAITelemetryService = () => {
    return new AITelemetryService()
}

export const ai = createAITelemetryService()
