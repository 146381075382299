export interface IAuth {
    access_token: string
    expires_in: number
    token_type: string
    refresh_token: string
    scope: string
}

export interface IUser {
    sub: string
    family_name: number
    given_name: string
    email: string
    role: string[] | string
    TenantId: string
    global_access: boolean
    DefaultTenantId: string
    Tenants: { Id: string; Name: string }[]
}

export enum Role {
    SuperAdmin = 'SuperAdmin',
    Admin = 'Admin',
    User = 'User',
    Guest = 'Guest',
}

export enum Scope {
    haystackAPI = 'haystackAPI',
    mappingAPI = 'mappingAPI',
    userService = 'userService',
    tenantAPI = 'tenantAPI',
    managementAPI = 'managementAPI',
    co2API = 'co2API',
}
