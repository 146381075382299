import { Config as ConfigGallery, config as configGallery } from '@unicaiot/unica-iot-gallery-core'

export interface Config extends ConfigGallery {
    preferences: {
        preferencesCombined: (userId: string) => string
        preferencesGlobal: string
        preferencesTenant: string
        preferencesUser: (userId: string) => string
    }
}

export const config: Config = {
    ...configGallery,
    preferences: {
        preferencesCombined: (userId: string) => `p/preferences/fetch/${userId}`,
        preferencesGlobal: 'p/preferences/global/fetch',
        preferencesTenant: 'p/preferences/tenant/fetch',
        preferencesUser: (userId: string) => `p/preferences/user/fetch/${userId}`,
    },
}
