import styles from './PageLayout.module.scss'

import React from 'react'
import classNames, { Argument } from 'classnames'

import { Icon, Icons } from '../../Icon/Icon'
import { Link } from 'react-router-dom'
import Title, { FontSize, HeadingLevel } from '../../Typography/Title'
import { Footer } from '../Footer/Footer'
import { ActivityIndicator, ActivityIndicatorEnumType } from '@unicaiot/unica-iot-gallery-core'
import { LayoutType } from '../../Types/Types'

interface Props {
    className?: Argument
    backRoute?: string
    wrapperClassName?: Argument
    contentClassName?: Argument
    content: () => JSX.Element
    title: string | JSX.Element
    subTitle?: string | JSX.Element
    pageType?: LayoutType
    showFooter?: boolean
    showHeader?: boolean
    fullScreen?: boolean
    loading?: boolean
}

export const PageLayout: React.FunctionComponent<Props> = props => {
    const { content, backRoute, title, pageType, subTitle, loading } = props

    const currentPageType = pageType || LayoutType.light

    return (
        <div
            className={classNames(styles.container, props.className, {
                [styles.light]: currentPageType === LayoutType.light,
                [styles.dark]: currentPageType === LayoutType.dark,
                [styles.showFooter]: !!props.showFooter,
                [styles.showHeader]: !!props.showHeader,
                [styles.fullScreen]: !!props.fullScreen,
            })}
        >
            <div className={classNames(styles.wrapper, props.wrapperClassName)}>
                {loading ? (
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                ) : (
                    <>
                        <div className={styles.header}>
                            {backRoute && (
                                <Link className={styles.close} to={backRoute}>
                                    <Icon icon={Icons.close} />
                                </Link>
                            )}
                            {typeof title === 'string' ? (
                                <Title className={styles.title} headingLevel={HeadingLevel.h1}>
                                    {title}
                                </Title>
                            ) : (
                                title
                            )}
                            {subTitle &&
                                (typeof subTitle === 'string' ? (
                                    <Title
                                        className={styles.subTitle}
                                        fontSize={FontSize.size14}
                                        headingLevel={HeadingLevel.h2}
                                    >
                                        {subTitle}
                                    </Title>
                                ) : (
                                    subTitle
                                ))}
                        </div>
                        <div className={classNames(styles.content, props.contentClassName)}>{content()}</div>
                    </>
                )}
            </div>
            {props.showFooter && <Footer />}
        </div>
    )
}
