export type PreferenceKey =
    | 'airInsight_announcement_message'
    | 'airInsight_types_disabled'
    | 'airInsight_tenants_with_co2_predictions_enabled'
    | string
export const AnnouncementPreferenceKey: PreferenceKey = 'airInsight_announcement_message'
export const DisabledTypesPreferenceKey: PreferenceKey = 'airInsight_types_disabled'
export const TenantsWithCo2PredictionsEnabledPreferenceKey: PreferenceKey =
    'airInsight_tenants_with_co2_predictions_enabled'

export interface Preference<T> extends Record<PreferenceKey, T> {}

export enum Type {
    CO2 = 'co2',
    Temperature = 'temperature',
    Humidity = 'humidity',
}
