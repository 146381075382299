import classNames, { Argument } from 'classnames'

import React, { Fragment } from 'react'
import posed, { PoseGroup } from 'react-pose'
import { LayoutType } from '../Types/Types'
import styles from './ModalManager.module.scss'

const Div = posed.div({
    enter: {
        opacity: 1,
        transition: {
            type: 'tween',
            ease: 'anticipate',
        },
    },
    exit: {
        opacity: 0,
        transition: {
            type: 'tween',
            ease: 'easeOut',
        },
    },
})

const Inner = posed.div({
    enter: {
        translateY: 0,
    },
    exit: {
        translateY: window.innerHeight * 1.5,
        transition: {
            type: 'tween',
            ease: 'easeOut',
        },
    },
})

interface Props {
    render: (requestOpen: () => void) => JSX.Element
    renderModal: (requestClose: () => void) => JSX.Element
    dismissable?: boolean
    className?: Argument
    modalType?: LayoutType
    showHeader?: boolean
}

interface State {
    modalIsShown: boolean
}

export class ModalManager extends React.Component<Props, State> {
    public static shownModalCount: number = 0

    public static defaultProps: Partial<Props> = {
        dismissable: true,
    }

    public state: State = {
        modalIsShown: false,
    }

    public id: string

    private lastActiveElement: HTMLElement | undefined

    public componentWillUnmount() {
        if (this.state.modalIsShown) {
            if (--ModalManager.shownModalCount === 0) {
                document.body.style.overflow = ''
            }
        }
    }

    public toggleModal = () => {
        const { dismissable } = this.props

        if (this.state.modalIsShown && !dismissable) {
            return
        }

        if (!this.state.modalIsShown) {
            this.id = `id-${Math.random().toString(36).substring(7)}`
            this.lastActiveElement = document.activeElement as HTMLElement
        }

        this.setState(
            (state: State) => ({
                modalIsShown: !state.modalIsShown,
            }),
            () => {
                if (this.state.modalIsShown) {
                    ModalManager.shownModalCount += 1
                    document.body.style.overflow = 'hidden'
                } else {
                    if (--ModalManager.shownModalCount === 0) {
                        document.body.style.overflow = ''
                    }

                    if (this.lastActiveElement) {
                        this.lastActiveElement.focus()
                    }

                    this.lastActiveElement = undefined
                }
            }
        )
    }

    public render() {
        const { render, renderModal, modalType, showHeader } = this.props
        const { modalIsShown } = this.state

        const currentPageType = modalType || LayoutType.dark

        return (
            <Fragment>
                {render && render(this.toggleModal)}
                <PoseGroup animateOnMount={true}>
                    {modalIsShown && (
                        <Div
                            key={`overlay-div-${this.id}`}
                            className={classNames(styles.overlay, {
                                [styles.light]: currentPageType === LayoutType.light,
                                [styles.dark]: currentPageType === LayoutType.dark,
                                [styles.showHeader]: !!showHeader,
                            })}
                            onClick={this.handleOnOverlayClick}
                        >
                            <Inner id={this.id} className={styles.modal}>
                                {renderModal(this.toggleModal)}
                            </Inner>
                        </Div>
                    )}
                </PoseGroup>
            </Fragment>
        )
    }

    private handleOnOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()

        const { modalIsShown } = this.state

        if (!modalIsShown) {
            return
        }

        const target = event.target as HTMLElement

        if (target.closest(`#${this.id} > *:first-child`)) {
            return
        }

        if (target.closest('#popover-root')) {
            return
        }

        this.toggleModal()
    }
}
