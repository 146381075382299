import styles from './LinkButton.module.scss'

import React from 'react'
import classNames, { Argument } from 'classnames'

import { ButtonStyleType } from '../Button'
import { Link } from 'react-router-dom'
import { Icon, IconType } from '../../Icon/Icon'

interface Props {
    className?: Argument
    buttonStyle?: ButtonStyleType
    leftIcon?: IconType
    rightIcon?: IconType
    iconSize?: number
    to: string
    isExternal?: boolean
}

export class LinkButton extends React.Component<Props> {
    public render() {
        const { isExternal, to } = this.props

        if (isExternal) {
            return (
                <a href={to} className={this.getClassNames()}>
                    {this.renderChildren()}
                </a>
            )
        }

        return (
            <Link to={to} className={this.getClassNames()}>
                {this.renderChildren()}
            </Link>
        )
    }

    private getClassNames() {
        const { buttonStyle, className } = this.props

        const currentButtonStyle = buttonStyle || ButtonStyleType.primary
        return classNames(styles.container, className, {
            [styles.isPrimary]: currentButtonStyle === ButtonStyleType.primary,
            [styles.isSecondary]: currentButtonStyle === ButtonStyleType.secondary,
            [styles.isTertiary]: currentButtonStyle === ButtonStyleType.tertiary,
            [styles.isExternalLink]: currentButtonStyle === ButtonStyleType.externalLink,
        })
    }

    private renderChildren() {
        const { children } = this.props

        return (
            <>
                {this.renderLeftIcon()}
                <span>{children}</span>
                {this.renderRightIcon()}
            </>
        )
    }

    private renderLeftIcon() {
        const { leftIcon, iconSize } = this.props

        if (!leftIcon) {
            return null
        }

        return <Icon icon={leftIcon} size={iconSize} />
    }

    private renderRightIcon() {
        const { rightIcon, iconSize } = this.props

        if (!rightIcon) {
            return null
        }

        return <Icon icon={rightIcon} size={iconSize} />
    }
}
