import styles from './Text.module.scss'
import React, { FunctionComponent } from 'react'

import classNames, { Argument } from 'classnames'

export enum TextColorEnumType {
    white = '#fff',
    red = '#ee6464',
    yellow = '#ea9946',
    green = '#4baa6f',
    grey = '#aeaeae',
}

export enum TextSizeEnumType {
    small = 'small',
    medium = 'medium',
    large = 'large',
    extralarge = 'extralarge',
}

export enum TextWeightEnumType {
    bold = 'bold',
    w500 = '500',
}

export enum TextStyleEnumType {
    italic = 'italic',
}

interface Props {
    className?: Argument
    color?: TextColorEnumType
    size?: TextSizeEnumType
    weight?: TextWeightEnumType
    style?: TextStyleEnumType
}

export const InfoText: FunctionComponent<Props> = ({ children, color, className, size, weight, style }) => {
    return (
        <p
            className={classNames(styles.container, className, {
                [styles.isColorWhite]: color === TextColorEnumType.white,
                [styles.isColorRed]: color === TextColorEnumType.red,
                [styles.isColorYellow]: color === TextColorEnumType.yellow,
                [styles.isColorGreen]: color === TextColorEnumType.green,
                [styles.isColorGrey]: color === TextColorEnumType.grey,
                [styles.isSizeSmall]: size === TextSizeEnumType.small,
                [styles.isSizeMedium]: size === TextSizeEnumType.medium,
                [styles.isSizeLarge]: size === TextSizeEnumType.large,
                [styles.isSizeExtraLarge]: size === TextSizeEnumType.extralarge,
                [styles.isWeight500]: weight === TextWeightEnumType.w500,
                [styles.isWeightBold]: weight === TextWeightEnumType.bold,
                [styles.isStyleItalic]: style === TextStyleEnumType.italic,
            })}
        >
            {children}
        </p>
    )
}
