import styles from './Button.module.scss'

import React from 'react'

import classNames, { Argument } from 'classnames'
import { Icon, IconType } from '../Icon/Icon'
import { ActivityIndicator } from '@unicaiot/unica-iot-gallery-core'

export enum ButtonStyleType {
    primary = 'PRIMARY',
    secondary = 'SECONDARY',
    tertiary = 'TERTIARY',
    externalLink = 'EXTERNALLINK',
}

export interface ButtonProps {
    className?: Argument
    buttonStyle?: ButtonStyleType
    loading?: boolean
    isDisabled?: boolean
    icon?: IconType
    iconSize?: number
    onClick?: () => void
    isSubmit?: boolean
}

export class Button extends React.Component<ButtonProps> {
    public render() {
        const { children, buttonStyle, icon, iconSize, onClick, isSubmit, loading, className } = this.props
        const currentButtonStyle = buttonStyle || ButtonStyleType.primary

        return (
            <button
                type={isSubmit ? 'submit' : 'button'}
                onClick={onClick}
                disabled={this.props.isDisabled}
                className={classNames(styles.wrapper, className, {
                    [styles.isPrimary]: currentButtonStyle === ButtonStyleType.primary,
                    [styles.isSecondary]: currentButtonStyle === ButtonStyleType.secondary,
                    [styles.isTertiary]: currentButtonStyle === ButtonStyleType.tertiary,
                    [styles.isExternalLink]: currentButtonStyle === ButtonStyleType.externalLink,
                    [styles.isDisabled]: !!this.props.isDisabled,
                })}
            >
                {loading ? (
                    <ActivityIndicator />
                ) : (
                    <>
                        {icon && <Icon icon={icon} size={iconSize} />}
                        <span>{children}</span>
                    </>
                )}
            </button>
        )
    }
}
