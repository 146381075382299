import styles from './Footer.module.scss'

import React from 'react'
import classNames, { Argument } from 'classnames'

import { UnicaLogo } from '../../Logo/UnicaLogo'

interface Props {
    className?: Argument
}

export const Footer: React.FunctionComponent<Props> = props => {
    return (
        <div className={classNames(styles.container, props.className)}>
            <UnicaLogo className={styles.logo} />
        </div>
    )
}
