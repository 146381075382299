import { useEffect } from 'react'

export default function useResize() {
    const appSize = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
        doc.style.setProperty('--app-width', `${window.innerWidth}px`)
    }
    useEffect(() => {
        // Set height of browser window as a variable to fake 100vh for mobile devices
        appSize()
        window.addEventListener('resize', appSize)

        // Unmount event listener on unmount
        return window.removeEventListener('resize', appSize)
    }, [])
}
