/* eslint-disable  @typescript-eslint/no-explicit-any */

export class PageError extends Error {
    error: any

    constructor(error: any) {
        super(error.message)
        this.error = error
    }
}
